import { TypeaheadItem } from 'presentation/components/typeahead/typeahead'
import { atom } from 'recoil'

export interface ModalEditReportHeaderState {
  cancerType: TypeaheadItem
  stages: TypeaheadItem
  status: TypeaheadItem
  priorTreatments: string | null
  payer: TypeaheadItem
  comorbidities: string | null
  address: string | null
  insurance_id_number: string | null
  group_id_number: string | null
  specimen?: string | null
  specimenType?: string | null
  reportDate?: string | null
  biopsyDate?: string | null
  ct_dna_tumor_fraction?: string | null
  microsatellite_instability_status?: string | null
  tumor_mutational_burden?: string | null
}

export const modalEditReportHeaderAtom = atom<ModalEditReportHeaderState>({
  key: 'modalEditReportHeaderAtom',
  default: {
    cancerType: null,
    stages: null,
    status: null,
    priorTreatments: null,
    payer: null,
    comorbidities: null,
    address: null,
    insurance_id_number: null,
    group_id_number: null,
    specimen: null,
    specimenType: null,
    reportDate: null,
    biopsyDate: null,
    ct_dna_tumor_fraction: null,
    microsatellite_instability_status: null,
    tumor_mutational_burden: null,
  },
})
