import { GeneInfoResponse } from 'domain/usecases/get-genes-info'
import { Patient } from 'domain/usecases/get-patient'
import { Report } from 'domain/usecases/get-report'
import { PatientStatus, toStatus } from 'domain/usecases/list-patients'
import { atom } from 'recoil'
import { ModalEditReportHeaderState } from './organisms/modal-edit-report-header/modal-edit-report-header-atom'
import { ReportEdition } from './organisms/modal-edit-report-header/modal-edit-report-header'

interface ReportPageState {
  report: Report | null
  reportPdfLink?: string | null
  isLoadingReport?: boolean | null
  isLoadingGenes?: boolean | null
  genesInfo?: GeneInfoResponse | null
}

export const reportAtom = atom<ReportPageState>({
  key: 'reportAtom',
  default: {
    report: null,
    reportPdfLink: null,
    isLoadingReport: true,
    genesInfo: null,
    isLoadingGenes: null,
  },
})

export const reportToTypeaheadItem = (report: ReportEdition) => {
  return {
    cancerType: {
      label: report?.cancer_type as string,
      value: report?.cancer_type as string,
    },
    stages: {
      label: report?.cancer_stage as string,
      value: report?.cancer_stage as string,
    },
    status: {
      label: toStatus[report?.status as PatientStatus] as string,
      value: report?.status as string,
    },
    comorbidities: report.co_morbidities,
    payer: {
      label: report?.payer as string,
      value: report?.payer as string,
    },
    priorTreatments: report.prior_treatments,
    address: report.address,
    group_id_number: report.group_id_number,
    insurance_id_number: report.insurance_id_number,
    biopsyDate: report.biopsyDate,
    reportDate: report.reportDate,
    specimen: report.specimen,
    specimenType: report.specimenType,
    ct_dna_tumor_fraction: report.ct_dna_tumor_fraction,
    microsatellite_instability_status: report.microsatellite_instability_status,
    tumor_mutational_burden: report.tumor_mutational_burden,
  } as ModalEditReportHeaderState
}
