import { IonSkeletonText, IonSpinner } from '@ionic/react'
import { GeneInfoItem } from 'domain/usecases/get-genes-info'
import { ReportStatus } from 'domain/usecases/get-report'
import { route } from 'main/routes'
import { ButtonRound, FlexGrid, MutationAmplification, NotFound, Title } from 'presentation/components'
import ChartBar from 'presentation/components/chart-bar/chart-bar'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import { reportAtom } from '../../report-atom'
import GenomicVariantsItem from './genomic-variants-item'
import './genomic-variants.scss'

const GenomicVariants: React.FC = () => {
  const { report, isLoadingReport, genesInfo, isLoadingGenes } = useRecoilValue(reportAtom)
  const [genomicVariantsShow, setGenomicVariantsShow] = useState(report?.genomic_variants)
  const qtyToShow = 4
  const [showShowMore, setShowShowMore] = useState<null | boolean>(null)
  const hasGenomicVariants = report?.genomic_variants && report.genomic_variants.length
  const navigate = useHistory()

  useEffect(() => {
    setGenomicVariantsShow(report?.genomic_variants?.slice(0, qtyToShow))
  }, [report?.genomic_variants])

  return (
    <section className="genomic-variants">
      {report?.status === ReportStatus.processing && !isLoadingReport ? (
        <div className="gv-processing">
          <NotFound>The report is being processed</NotFound>
        </div>
      ) : (
        <>
          {isLoadingReport ? (
            <div className="gv-grid">
              <IonSkeletonText animated style={{ height: '130px', width: '170px' }} />
              <IonSkeletonText animated style={{ height: '130px', width: '170px' }} />
              <IonSkeletonText animated style={{ height: '130px', width: '170px' }} />
              <IonSkeletonText animated style={{ height: '130px', width: '170px' }} />
            </div>
          ) : (
            <>
              <FlexGrid justifyContent="space-between">
                <Title size="h2" className="gv-custom-title">
                  Genomic Variants
                </Title>
                <FlexGrid gap={16}>
                  <MutationAmplification />
                </FlexGrid>
              </FlexGrid>
              <div className="gv-grid">
                {genomicVariantsShow &&
                  genomicVariantsShow.map((variant, index) => (
                    <GenomicVariantsItem
                      item={variant}
                      key={index}
                      onSearchLink={() => {
                        navigate.push(route.explore.url, {
                          geneName: variant.name,
                          cancerType: report?.patient.cancer_type || report?.diagnosis,
                        })
                      }}
                      showFrquencyLabel={!!(genesInfo && genesInfo[variant.name] && genesInfo[variant.name].length)}
                      chart={
                        isLoadingGenes ? (
                          <FlexGrid justifyContent={'center'}>
                            <IonSpinner name="dots" />
                          </FlexGrid>
                        ) : genesInfo && genesInfo[variant.name] ? (
                          genesInfo[variant.name].map((chartItem, key) => (
                            <ChartBar key={key} textInTheBar={true} geneInfo={chartItem as GeneInfoItem} />
                          ))
                        ) : (
                          <></>
                        )
                      }
                    />
                  ))}
              </div>
            </>
          )}

          {!genomicVariantsShow ||
            (!genomicVariantsShow.length && !isLoadingReport && (
              <div className="gv-processing">
                <NotFound>No Genomic Variants available for the patient</NotFound>
              </div>
            ))}

          {(hasGenomicVariants as number) > qtyToShow && showShowMore !== false && !isLoadingReport ? (
            <div className="gv-show-more">
              <ButtonRound
                onClick={() => {
                  setGenomicVariantsShow(report?.genomic_variants)
                  setShowShowMore(false)
                }}
                size="small"
              >
                + More Genomic Variants
              </ButtonRound>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </section>
  )
}

export default GenomicVariants
