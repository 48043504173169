import { IonButton, IonContent, IonFooter, IonInput, IonModal, IonSpinner, IonToolbar, useIonToast } from '@ionic/react'
import { Patient } from 'domain/usecases/get-patient'
import { PatientsService } from 'infra/services/patients-service'
import { pencil } from 'ionicons/icons'
import { ModalTitle } from 'presentation/components'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { reportToTypeaheadItem } from '../../report-atom'
import { modalEditReportHeaderAtom, ModalEditReportHeaderState } from './modal-edit-report-header-atom'
import './modal-edit-report-header.scss'
import { GenomicVariants, SelectedTreatments, Treatment } from 'domain/usecases/get-report'
import { ReportsService } from 'infra/services/reports-service'

export type ReportEdition = Patient &
  Partial<{
    reportID: string
    genomicVariants: GenomicVariants[]
    tumor_mutational_burden: string
    tmb_percentile: string
    microsatellite_instability_status: string
    treatments: Treatment[]
    selected_treatments: SelectedTreatments
    ct_dna_tumor_fraction: string
  }>

type ModalEditReportHeaderProps = React.ComponentPropsWithoutRef<typeof IonModal> & {
  modal: React.RefObject<HTMLIonModalElement>
  patientsService: PatientsService
  reportsService: ReportsService
  reportEdition: ReportEdition
  fields: {
    reportID: boolean
    specimen?: boolean
    specimenType?: boolean
    reportDate?: boolean
    biopsyDate?: boolean
    genomicVariants?: boolean
    tumor_mutational_burden?: boolean
    tmb_percentile?: boolean
    microsatellite_instability_status?: boolean
    treatments?: boolean
    ct_dna_tumor_fraction?: boolean
  }
  headerTitle: string
}

type ReportEditionArrayEditing = {
  [key: number]: {
    name: string
    id: number | string
  }
} | null

const ModalEditReportHeader: React.FC<ModalEditReportHeaderProps> = (props) => {
  const [editState, setEditState] = useRecoilState(modalEditReportHeaderAtom)
  const [isSaving, setSaving] = useState(false)


  const [genomicVariants, setGenomicVariants] = useState<ReportEditionArrayEditing>(
    props.reportEdition.genomicVariants?.map((genomicVariant) => ({ name: genomicVariant.name, id: genomicVariant.id })) || null
  )

  const [treatments, setTreatments] = useState<ReportEditionArrayEditing>(
    props.reportEdition.treatments?.map((treatment) => ({ name: treatment.name, id: treatment.id })) || null
  )

  const [presentToast] = useIonToast()

  const save = () => {
    setSaving(true)
    props.reportsService
      .editReport({
        tumor_mutational_burden: editState.tumor_mutational_burden as string,
        microsatellite_instability_status: editState.microsatellite_instability_status as string,
        ct_dna_tumor_fraction: editState.ct_dna_tumor_fraction as string,
        report_id: props.reportEdition.reportID as string,
        report_date: editState.reportDate as string,
        genomic_variants: Object.values(genomicVariants || {}).map((item) => ({ name: item.name, id: Number(item.id) })),
        treatments: Object.values(treatments || {}).map((item) => ({ name: item.name, id: Number(item.id) }))
      })
      .then(() => props.modal.current?.dismiss({ wasEdited: true }))
      .catch((message) => {
        presentToast({
          message,
          duration: 5000,
          color: 'danger',
        })
      })
      .finally(() => setSaving(false))
  }

  useEffect(() => {
    setEditState(() => reportToTypeaheadItem(props.reportEdition) as ModalEditReportHeaderState)
  }, [props.reportEdition])

  return (
    <>
      <IonContent className="ion-padding modal-edit-report-header">
        <ModalTitle icon={pencil} label={props.headerTitle} />
 
        {props.fields.specimen ? (
          <div className="free-text-input">
            <span>Specimen</span>
            <IonInput
              value={editState.specimen}
              onIonInput={(e) =>
                setEditState((s) => ({ ...s, specimen: e.target.value } as ModalEditReportHeaderState))
              }
              placeholder="-"
            />
          </div>
        ) : undefined}
        {props.fields.specimenType ? (
          <div className="free-text-input">
            <span>Specimen Type</span>
            <IonInput
              value={editState.specimenType}
              onIonInput={(e) =>
                setEditState((s) => ({ ...s, specimenType: e.target.value } as ModalEditReportHeaderState))
              }
              placeholder="-"
            />
          </div>
        ) : undefined}
        {props.fields.reportDate ? (
          <div className="free-text-input">
            <span>Report Date</span>
            <IonInput
              value={editState.reportDate}
              onIonInput={(e) =>
                setEditState((s) => ({ ...s, reportDate: e.target.value } as ModalEditReportHeaderState))
              }
              placeholder="-"
            />
          </div>
        ) : undefined}
        {props.fields.biopsyDate ? (
          <div className="free-text-input">
            <span>Biopsy Date</span>
            <IonInput
              value={editState.biopsyDate}
              onIonInput={(e) =>
                setEditState((s) => ({ ...s, biopsyDate: e.target.value } as ModalEditReportHeaderState))
              }
              placeholder="-"
            />
          </div>
        ) : undefined}
        {props.fields.ct_dna_tumor_fraction ? (
          <div className="free-text-input">
            <span>CT DNA Tumor Fraction</span>
            <IonInput
              value={editState.ct_dna_tumor_fraction}
              onIonInput={(e) =>
                setEditState((s) => ({ ...s, ct_dna_tumor_fraction: e.target.value } as ModalEditReportHeaderState))
              }
              placeholder="-"
            />
          </div>
        ) : undefined}
        {props.fields.microsatellite_instability_status ? (
          <div className="free-text-input">
            <span>Microsatellite Instability Status</span>
            <IonInput
              value={editState.microsatellite_instability_status}
              onIonInput={(e) =>
                setEditState((s) => ({ ...s, microsatellite_instability_status: e.target.value } as ModalEditReportHeaderState))
              }
              placeholder="-"
            />
          </div>
        ) : undefined}
        {props.fields.tumor_mutational_burden ? (
          <div className="free-text-input">
            <span>Tumor Mutational Burden</span>
            <IonInput
              value={editState.tumor_mutational_burden}
              onIonInput={(e) =>
                setEditState((s) => ({ ...s, tumor_mutational_burden: e.target.value } as ModalEditReportHeaderState))
              }
              placeholder="-"
            />
          </div>
        ) : undefined}
        {props.fields.genomicVariants ? (
          <div>
            {props.reportEdition.genomicVariants?.map((genomicVariant, index) => (
              <div key={index} className="free-text-input">
                <span>Genomic Variant extracted: {genomicVariant.name} </span>
                <IonInput
                  value={genomicVariant.name}
                  readonly
                  onIonInput={(e) => {
                    const newValue = e.target.value
                    setGenomicVariants(
                      (s) => ({ ...s, [index]: { name: newValue, id: genomicVariant.id } } as ReportEditionArrayEditing)
                    )
                  }}
                  placeholder={genomicVariant.name}
                />
              </div>
            ))}
          </div>
        ) : undefined}
        {props.fields.treatments && props.reportEdition.selected_treatments?.treatments ? (
          <div>
            {props.reportEdition.selected_treatments?.treatments.map((treatment, index) => (
              <div key={index} className="free-text-input">
                <span>Treatment extracted: {treatment.name} </span>
                <IonInput
                  readonly
                  value={treatment.name}
                  onIonInput={(e) => {
                    const newValue = e.target.value
                    setTreatments(
                      (s) =>
                        ({ ...s, [treatment.id]: { name: newValue, id: treatment.id } } as ReportEditionArrayEditing)
                    )
                  }}
                  placeholder={treatment.name}
                />
              </div>
            ))}
          </div>
        ) : undefined}
        {props.fields.treatments ? (
          <div>
            {props.reportEdition.treatments?.map((treatment, index) => (
              <div key={index} className="free-text-input">
                <span>Treatment extracted: {treatment.name} </span>
                <IonInput
                  readonly
                  value={treatment.name}
                  onIonInput={(e) => {
                    const newValue = e.target.value
                    setTreatments(
                      (s) =>
                        ({ ...s, [treatment.id]: { name: newValue, id: treatment.id } } as ReportEditionArrayEditing)
                    )
                  }}
                  placeholder={treatment.name}
                />
              </div>
            ))}
          </div>
        ) : undefined}
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButton slot="end" fill="outline" onClick={() => props.modal.current?.dismiss({ wasEdited: false })}>
            Cancel
          </IonButton>

          <IonButton
            slot="end"
            fill="solid"
            onClick={() => save()}
            disabled={isSaving}
          >
            {isSaving ? <IonSpinner /> : 'Save'}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default ModalEditReportHeader
